
<script setup>
  import 'bootstrap/dist/css/bootstrap.css';
  import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
  import './lib/google-fonts/css/google-fonts.css';
  import './lib/fontawesome/css/all.min.css';
</script>

<template>
  <div id="app" class="container-fluid">
    <RouterView :key="$route.fullPath" />
  </div>
</template>
