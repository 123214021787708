import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes.js'

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to) {
        let position = {}
        if (to.hash) {
            position = {
                selector : to.hash
            };
        } else {
            position = {left : 0 , top : 0}
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(position);
            }, 100)
        });
    }
})


export default router