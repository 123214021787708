import fastlocalStorage from  './fast-local-storage.js';

window.fastLocalStorage = fastlocalStorage
const persistInterval = 60 * 60 * 1000; // 1 hour

const localStorageClient = {

   load: (key) => {
      let storage = false;
      try {
         storage = window.fastLocalStorage.getItem(key) || false;
         if (storage) {
            storage = JSON.parse(storage);
         }
      } catch (e) {
      }

      if (storage && new Date().getTime() < storage.ts) {
         return Promise.resolve(storage);
      }

      return Promise.resolve(null);
   },
   save: (key, value, interval) => {
      let ts = new Date().getTime() + (interval || persistInterval);
      let record = {data: value, ts: ts};
      try {
         window.fastLocalStorage.setItem(key, JSON.stringify(record));
      } catch (e) {
      }
   },
   clear(){
      window.fastLocalStorage.clear();
   }
}

export default localStorageClient;