import moment from "moment";
import axios from "axios";
import qs from "qs";

import {DateHelper} from "@utils";

const dateTransformer = (data) => {
	if (data instanceof Date || moment.isMoment(data)) {
		return DateHelper.toISOString(data);
	}
	if (Array.isArray(data)) {
		return data.map(val => dateTransformer(val));
	}
	if (typeof data === "object" && data !== null) {
		return Object.fromEntries(Object.entries(data).map(([key, val]) => [key, dateTransformer(val)]));
	}

	return data;
};

const api = axios.create({
	transformRequest: [dateTransformer].concat(axios.defaults.transformRequest)
});

api.interceptors.request.use(config => {
	config.metadata = { timestamp: Date.now() };
	config.paramsSerializer = {
		serialize: (params) => {
			return qs.stringify(params, {
				skipNulls: true,
				encodeValuesOnly: true,
				allowDots: true,
				arrayFormat: 'repeat',
				serializeDate: (date) => DateHelper.toISOString(date)
			});
		}
	};

	return config;
});

api.interceptors.response.use(response => {
	return response;
}, error => {
	if (error.response && error.response.request && error.response.request.status === 401) {
		window.location = '/inloggen?ReturnUrl=' + encodeURIComponent(window.location.pathname + window.location.search);
	}
	if (error.response && error.response.request && error.response.request.status === 400) {
		console.log(error.request.response);
	}

	return Promise.reject(error);
});

const createAbortController = function () {
	return new AbortController();
};

export {api as default, createAbortController};